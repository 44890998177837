//**************************
//Latest Deals
//**************************
.latest-deals {
    padding-bottom: 7rem;
    img {
        border-radius: 20px;
        width: 100%;
        height: auto;
        @media(max-width: 767px) { border-radius: 15px; }
    }

    .row {
        padding-top: 0;
        padding-bottom: 0;
    }

    .image-reveal {
        border-radius: 20px;
        @media(max-width: 767px) { border-radius: 15px; }
    }
}