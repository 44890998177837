//**************************
//Banner Slider
//**************************
.bannerSlider {
    background: transparent linear-gradient(180deg, #C4E83E 0%, #2B902A 100%) 0% 0% no-repeat padding-box;
    overflow: hidden;
    @media(max-width: 767px) { background: none }

    .slick-track{
        .slick-slide:nth-child(2){
            &::after{
                position: absolute;
                content: '*Terms & Conditions Apply';
                top: 5rem;
                left: 46.5vw;
                transform: rotate(-90deg);
                z-index: 2;
                color: $ai-white;
                font-size: 0.7rem;
            }

            @media screen and (max-width: 768px) {
                &::after{
                    left: 2vw;
                    top: 35%;
                    transform: rotate(0deg);
                }
            }
        }
    }

    .slick-slide {
        @media screen and (min-width: 1440px) and (max-width: 1500px) {
            height: 83vh;
        }
        .item {
            >div:last-child {
                width: fit-content;
                overflow: hidden;
                width: 50%;
                margin-left: auto;
                @media(min-width: 992px) { margin-bottom: -6px; }
                @media(max-width: 767px) { 
                    width: 100%;
                }
                img {
                    width: 100%;
                    margin-left: auto;
                    height: auto;
                }
            }
        }


    }

    .item {
        position: relative;
        margin-bottom: -10px;

        .container {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            @media(max-width: 767px) {
                position: static;
                transform: none;
                padding-top: 50px;
                padding-bottom: 60px;
                background: transparent linear-gradient(180deg, #C4E83E 0%, #2B902A 100%) 0% 0% no-repeat padding-box;
            }
        }
    }

    .col-md-6 {
        padding-right: 40px;
    }

    h2 {
        color: $ai-white;
        margin-bottom: 35px;
        font-weight: 400;
        font-size: 52px;
        padding-right: 4rem;
        &.ai-ft-45{
            font-size: 45px;
            @media(max-width: 1199px) { font-size: 36px; }
            @media(max-width: 991px) { font-size: 28px; }
        }
        @media(max-width: 1199px) { font-size: 36px; }
        @media(max-width: 991px) { font-size: 28px; }
        @media(max-width: 436px) { padding-right: 0rem; }
    }

    .btn {
        margin-right: 25px;
        @media(max-width: 767px) {
            display: block;
            width: fit-content;
            margin-bottom: 20px;
        }
    }

    a {
        color: $ai-white;
        transition: $transition;
        &:hover {
            color: $range-input-red;
        }

        &.vc_btn3 {
            font-size: clamp(1rem, 0.8667rem + 0.2431vw, 1.2rem);
            font-weight: 500;
            border-radius: 10px;
            padding: 10px 15px;
            background-color: $ai-white;
            border: 1px solid $ai-white;
            text-decoration: none;
            color: $ai-green;
            &:hover {
                background-color: $ai-green;
                color: $ai-white;
                border: 1px solid $ai-green;
            }
        }
    }

    .slick-dots {
        bottom: 10%;
        text-align: left;
        max-width: 1320px;
        left: 50%;
        transform: translateX(-50%);
        padding-left: 15px;

        li {
            margin: 0;
            width: 15px;
            height: 15px;
            button {
                &::before {
                    color: $ai-white;
                }
            }
            &.slick-active {
                button {
                    &::before {
                        color: $ai-white;
                        font-size: 10px;
                    }
                }
            }
        }
    }

    .slick-slide {
        img {
            transform: translateX(100%);
            opacity: 0;
            transition: transform 1s ease, opacity .5s ease;
            //transform: scale(1);
            transform-origin: top;
            min-height: 625px;
            object-fit: cover;
            @media (min-width: 1600px){
                min-height: 825px;
            }
        }
        a{
            display: inline-block;
            &:last-child{
                transform: translateX(-100%);
                transition: transform 1s ease
            }
        }

        h2 {
            @media(min-width: 992px) {
                transform: translateY(-200%);
            }
        }

        &.slick-current {
            h2 {
                @media(min-width: 992px) {
                    transform: translateY(0);
                }
            }
            >div:last-child {
                overflow: hidden;
                img {
                    //transform: scale(1.05);
                }
            }
        }
        //Animation
        &.slick-active{
            img{
                transform: translateX(0);
                transform-origin: bottom;
                opacity: 1;
            }
            a{
                &:last-child{
                    transform: translate(0);
                }
            }
        }
    }
}