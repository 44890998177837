//***************************
//Subscribe
//***************************
.subscribe {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba($ai-green, .9);
    z-index: 111;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hide { display: none; }

    .wrapper {
        background-color: $ai-white;
        width: 75%;
        height: 550px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        padding: 0 100px;
        position: relative;
        @media(max-width: 991px) {
            justify-content: center;
            padding: 40px;
            height: auto;
        }
        @media(max-width: 767px) { 
            width: 90%; 
            border-radius: 15px;
        }

        .close {
            position: absolute;
            top: 30px;
            right: 30px;
            @media(max-width: 767px) {
                top: 0px;
                right: 0px;
            }

            .icon {
                display: inline-block;
                width: 15px;
                height: 15px;
                background-size: 100%;
                transition: $transition;
                &.icon-close {
                    background-image: url('../../images/Melon-Mobile-Close-Icon.svg');
                }
            }

            button {
                background-color: transparent;
                border: none;

                &:hover {
                    .icon {
                        &.icon-close {
                            background-image: url('../../images/Melon-Mobile-Close-Icon-red.svg');
                        }
                    }
                }
            }
        }

        .image {
            width: 50%;
            @media(max-width: 991px) { display: none; }
            img {
                width: 70%;
            }
        }

        .content {
            width: 50%;
            text-align: center;
            @media(max-width: 991px) { width: 100%; }

            input {
                width: 100%;
                border-radius: 20px;
                border: 3px solid $input-border;
                padding: 16px 15px;
            }

            .logo {
                margin-bottom: 45px;
            }

            h4 {
                color: $ai-red;
                font-weight: 700;
                font-size: 30px;
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 30px;
                font-size: 1.2rem;
            }

            .input {
                margin-bottom: 30px;
            }

            .btn {
                margin-bottom: 20px;
                color: $ai-white;
                font-weight: 500;
                &:hover {
                    color: $ai-green;
                }
            }

            .link {
                color: var(--bs-link-color);;
                transition: $transition;
                border: none;
                background: none;
                text-decoration: underline;
                &:hover {
                    color: $range-input-red;
                }
            }
        }
    }
}