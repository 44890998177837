.cookie-notice {
    display: flex;
    padding: 20px 30px;
    align-items: center;
    background-color: $ai-white;
    position: fixed;
    bottom: 0;
    z-index: 11;
    width: 100%;
    @media(max-width: 767px) {
        padding: 30px 25px;
        display: block;
    }
    &.hide { display: none; }

    .close {
        background-color: transparent;
        border: none;
        margin-left: 15px;
        @media(max-width: 767px) {
            position: absolute;
            right: 0;
            top: 0px;
            margin: 0 !important;
        }
    }

    p {
        font-size: 15px;
        margin-bottom: 0;
        @media(max-width: 767px) { 
            font-size: 10px; 
            margin-bottom: 15px;
        }

        a {
            color: $ai-black;
            font-weight: 400;
            &:hover {
                color: $ai-red;
            }
        }
    }

    .btn {
        margin-left: auto;
        @media(max-width: 767px) { 
            font-size: 14px; 
            width: 100%;
        }
    }

    .ml-auto {
        margin-left: 7rem;
        @media screen and (max-width: 767px){
            margin-left: 0px;
        }
    }

}