span.ai-reveal-box{
  height: fit-content;
  width: fit-content;
  display: inline-block;
  overflow: hidden;
  span.ai-animated-word{
    display: block;
    //transform: translateY(-100%);
    transition: transform .5s ease;
  }
}