//****************************
//Butons
//****************************
.btn {
    font-size: 15px;
    font-weight: 400;
    border-radius: 10px;
    padding: 10px 15px;
    overflow: hidden;
    @media(max-width: 991px) { font-size: 14px; }

    &.rounded-button.btn-white{
        border-radius: 50px;
        padding-block: 0px;
        color: green;
        & > span{
            gap: 1rem;
            font-weight: 500;
            font-size: 1.5rem;
            text-transform: uppercase;
            span{
                font-size: 2rem;
                font-weight: 300;
            }
        }
    }
    &:hover{
        .text{
            transition: color 0.5s ease-in 0s;
        }
    }

    .text {
        position: relative;
        z-index: 11;
        transition: color 0.4s ease-out .5s;
    }

    .bg {
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        transform: translateY(-50%),translateX(-50%);
        z-index: 1;
        pointer-events: none;
    }

    &.btn-primary {
        background-color: $button-green;
        border: 2px solid $button-green;
        &:hover {
            color: $button-green;
        }

        .bg {
            background-color: $ai-white;
        }
    }
    &.btn-outline {
        background-color: transparent;
        border: 1.5px solid $button-green;
        color: $ai-green;
        &:hover {
            color: $ai-white;
        }
        .bg {
            background-color: $button-green;
        }
    }
    &.btn-white {
        background-color: $ai-white;
        border: 2px solid $ai-white;
        color: $button-green;
        transition: border-color .2s ease-in .5s;
        &:hover {
            color: $ai-white;
            border: 2px solid $button-green;
            transition: border-color 0s ease-out;
        }

        .bg {
            background-color: $button-green;
        }
    }
}

@include browser(safari){
    .btn.btn-primary{
        z-index: 1;
    }
    [type=button]{
        -webkit-appearance: button-bevel;
    }
}