//****************************
//Footer
//****************************
#footer {
    @media(min-width: 1200px) {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: -1;
        background-color: $ai-white;
    }
    .ai-brand {
        width: 30%;
    }

    .ai-links {
        width: 17.5%;
    }

    h4 {
        color: $ai-red;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 24px;
    }

    .nav-link {
        font-size: clamp(1rem, 0.8667rem + 0.2431vw, 1.2rem);
        width: fit-content;
        position: relative;
        &::before {
            content: '';
            width: 0;
            height: 2px;
            background-color: $ai-red;
            bottom: -5px;
            position: absolute;
            transition: $transition;
            left: 0;
        }
        &:hover {
            color: $ai-red;
            &::before {
                width: 100%;
            }
        }
    }

    .nav-item {
        margin-bottom: 18px;
    }

    .ai-brand {
        p, a {
            line-height: 1.1;
            font-size: 14px;
            color: $ai-dark-grey;
            text-decoration: none;
            transition: all .3s;
        }

        a {
            &:hover {
                color: $ai-red;
            }
        }

        .navbar-brand {
            margin-bottom: 150px;
            @media(max-width: 991px) { margin-bottom: 40px; }
        }

        small {
            font-size: 12px;
            a {
                font-size: 12px;
            }
        }
    }

    .icons {
        a {
            display: inline-block;
            margin-right: 10px;
            transition: $transition;
            border-radius: 50%;
            text-align: center;
            position: relative;

            &::after {
                pointer-events: none;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                content: '';
                box-sizing: content-box;
                box-shadow: 0 0 0 3px #f6637e;
                top: 0;
                left: 0;
                opacity: 0;
                transition: 300ms;
            }

            &:hover {
                &::after {
                    opacity: 1;
                    transform: scale(1.15);
                }
            }

            svg {
                font-size: 30px;
                color: #f6637e;
            }
        }
    }

    .d-flex {
        @media(max-width: 991px) {
            flex-wrap: wrap;

            .ai-brand, .ai-links {
                width: 100%;
            }
        }
    }

    .copyright-mobile {
        padding-top: 2rem;
    }
}