.blog-post {
    display: flex;
    margin-bottom: 40px;
    img {
        width: 100%;
        height: 260px;
        object-fit: cover;
    }

    >a {
        display: inline-block;
        border-radius: 10px;
        overflow: hidden;
        border: 2px solid $ai-green;
        color: $ai-dark-green;
        text-decoration: none;
        transition: all .3s;
        width: 100%;

        .content {
            padding: 15px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                top: -16px;
                right: -16px;
                background: $ai-green;
                height: 32px;
                width: 32px;
                border-radius: 32px;
                transform: scale(0);
                transform-origin: 50% 50%;
                transition: transform 0.25s ease-out;
            }
        }

        h4 {
            font-family: $ai-fnt-poppins;
            font-weight: 700;
            font-size: 16px;
            line-height: 1.7;
        }

        .post-date {
            color: $ai-black;
            margin-bottom: 10px;
        }

        &:hover {
            //background-color: $ai-green;
            color: $ai-white;

            .content {
                &::before {
                    transform: scale(21);
                }
            }

            .post-date {
                color: $ai-white;
            }
        }
    }
}

.blog-posts {
    padding-bottom: 5rem;
}

.post-categories {
    display: flex;
    list-style: none;
    width: 100%;
    justify-content: center;
    gap: 25px;
    margin-bottom: 6rem;
    align-items: center;
    padding-left: 0;
    @media(max-width: 991px) {
        display: block;
    }

    li {
        padding: 15px 25px;
        border-radius: 10px;
        font-size: 18px;
        //line-height: 25px;
        transition: all .3s;

        &.active, &:hover {
            background-color: $range-input-red;
            color: $ai-white;
        }

        &:hover {
            cursor: pointer;
        }

        &:last-child {
            display: none;
        }

        &:first-child { order: 1; }
        &:nth-child(5) { order: 2 }
        &:nth-child(3) { order: 3 }
        &:nth-child(6) { order: 4 }
        &:nth-child(4) { order: 5 }
        &:nth-child(7) { order: 6 }
        &:nth-child(2) { order: 7 }
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding-bottom: 7rem;

    .page-number {
        background-color: rgba($ai-green, .1);
        border: 3px solid rgba($ai-green, .1);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 16px;


        &.active, &:hover {
            border-color: $ai-green;
        }
    }

    button {
        transition: all .3s;
    }
}

.single-blog {
    .tag {
        padding: 10px 25px;
        border-radius: 10px;
        font-size: 14px;
        background-color: $range-input-red;
        color: $ai-white;
        width: fit-content;
        font-weight: 700;
    }

    .post-content {
        padding-top: 5rem;
        padding-right: 50px;
        .row {
            margin-bottom: 5rem;

            img {
                width: 100%;
                height: auto;
            }
        }

        h3 {
            font-weight: 700;
            font-size: 26px;
        }

        p {
            font-size: 14px;
            line-height: 2.1;
        }

        .image {
            p {
                color: $ai-dark-grey
            }
        }
    }

    .post-links {
        display: flex;
        @media(max-width: 767px) {
            flex-wrap: wrap;
        }
        a {
            display: flex;
            height: 300px;
            width: 50%;
            position: relative;
            align-items: center;
            text-decoration: none;
            transition: all .3s;
            gap: 50px;
            @media(max-width: 767px) {
                width: 100%;
            }

            .icon {
                display: inline-block;
                width: 30px;
                height: 30px;
                background-size: 100%;
                background-repeat: no-repeat;
                transition: $transition;
            }

            &.next {
                justify-content: flex-start;
                padding-left: 100px;
                padding-right: 20px;
                @media(max-width: 767px) {
                    padding-left: 30px;
                }

                &::before {
                    background: transparent linear-gradient(270deg, #85C1374B 0%, #12963A 100%) 0% 0% no-repeat padding-box;
                }

                .icon {
                    background-image: url('../../images/icons/arrow-right-green.svg');
                }
            }
            &.prev {
                justify-content: flex-end;
                text-align: right;
                padding-right: 100px;
                padding-left: 20px;
                @media(max-width: 767px) {
                    padding-right: 30px;
                }

                &::before {
                    background: transparent linear-gradient(270deg, #12963A 0%, #85C1374B 100%) 0% 0% no-repeat padding-box;
                }

                .icon {
                    background-image: url('../../images/icons/arrow-left-green.svg');
                }
            }

            p {
                color: $range-input-red;
            }
            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                transition: $transition;
                z-index: 1;
                opacity: 0;
            }

            img {
                transition: $transition;
                opacity: 0;
            }

            &:hover {
                color: $ai-white;

                p {
                    color: $ai-white;
                }
                &::before {
                    opacity: 1;
                }

                img {
                    opacity: 1;
                }

                &.next {
                    .icon {
                        background-image: url('../../images/icons/arrow-right-light.svg');
                    }
                }
                &.prev {
                    .icon {
                        background-image: url('../../images/icons/arrow-left-light.svg');
                    }
                }
            }

            h3 {
                font-family: $ai-fnt-mont;
            }

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
                left: 0;
                top: 0;
            }

            i, div {
                position: relative;
                z-index: 1;
            }
        }
    }
}