//***************************
//Support
//***************************
.support-cta {
    padding-bottom: 7rem;
    .image {
        img {
            width: 40%;
            margin-bottom: 30px;
        }
    }

    h4 {
        font-weight: 700;
        font-size: 30px;
        font-family: $ai-fnt-mont;
        margin-bottom: 35px;
        padding: 0 40px;
        @media(max-width: 991px) { font-size: 24px; }
    }

    p {
        margin-bottom: 35px;
    }

    .row {
        @media(max-width: 767px) { gap: 6rem; }
    }
}