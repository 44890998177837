.ai-animation-overlay{
  .ai-curved-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden;
    position: absolute;
    left: -37%;
    width: 180%;
    height: 366px;
    z-index: 9999;
    .ai-animation-bottom{
      height: 2000px;
      width: 100%;

    }
  }
}


.ai-page-loader{
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-color: $ai-green;
  z-index: 999;
}

.ai-suspense-fallback{
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $ai-green;
}