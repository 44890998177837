// *******************
// Screen resolutions
// *******************

// Mobile
$mobile_xs: 374px;

$mobile_sm_min: 375px;
$mobile_sm_max: 639px;

$mobile_md_min: 640px;
$mobile_md_max: 767px;

// Tablet
$tablet_p_min: 768px;
$tablet_p_max: 1024px;

$tablet_l_min: 1024px;
$tablet_l_max: 1199px;

$tablet_ipad_min: 820px;
$tablet_ipad_max: 1180px;

// Laptop
$desktop_all: 1200px;
$desktop_retina_min: 1440px;
$desktop_retina_medium: 1500px;
$desktop_fhd: 1920px;




// *******************
// Screen resolutions
// *******************

@mixin breakpoint($point) {
  @if $point == desktopfullhd {
    @media only screen and (min-width: $desktop_fhd) { @content ; }
  }
  @if $point == desktopretina {
    @media only screen and (min-width: $desktop_retina_min) { @content ; }
  }
  @if $point == desktopretinamedium {
    @media only screen and (min-width: $desktop_retina_medium) { @content ; }
  }
  @if $point == desktopmedium {
    @media only screen and (min-width: $desktop_all) and (max-width: $desktop_retina_min) { @content ; }
  }
  @if $point == desktopall {
    @media only screen and (min-width: $desktop_all) { @content ; }
  }
  @if $point == tablet {
    @media only screen and (min-width: $tablet_p_min) and (max-width: $tablet_p_max){ @content ; }
  }
  @if $point == ipadAirTabletPortrait {
    @media only screen and (min-width: $tablet_ipad_min) and (min-height: $tablet_ipad_max)  {
      @content ;
    }
  }
  @if $point == ipadAirTabletLandscape {
    @media only screen and (min-width: $tablet_ipad_max) and (max-width: $tablet_ipad_min) and (orientation: landscape) {
      @content ;
    }
  }
  @if $point == tabletlandscape {
    @media only screen and (min-width: $tablet_l_min)  and (max-width: $tablet_l_max) and (orientation: landscape) { @content ; }
  }
  @if $point == mobileonly {
    @media only screen and (max-width: $tablet_p_min) and (max-height: $tablet_l_min)  { @content ; }
  }
  @if $point == mobilextrasmall {
    @media only screen and (max-width: $mobile_xs) { @content ; }
  }
  @if $point == mobilesmall {
    @media only screen and (min-width: $mobile_sm_min) and (max-width: $mobile_sm_max) { @content ; }
  }
  @if $point == mobilemedium {
    @media only screen and (min-width: $mobile_sm_min) and (max-width: $mobile_sm_max) { @content ; }
  }
  @if $point == mobilelandscape {
    @media only screen and (min-width: $mobile_md_min) and (max-height: $mobile_md_max) and (orientation: landscape)  { @content ; }
  }
}

@mixin browser($name){
  @if $name == internetexplorer {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { @content; }
  }
  @if $name == edge {
    @supports (-ms-ime-align:auto) { @content; }
  }
  @if $name == firefox {
    @-moz-document url-prefix() { @content; }
  }
  @if $name == chromeold {
    @media screen and (-webkit-min-device-pixel-ratio:0) { @content; }
  }
  @if $name == chrome {
    @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) { @content; }
  }
  @if $name == safariold {
    @media screen and (min-color-index:0) and (-webkit-min-device-pixel-ratio:0) { @content; }
  }
  @if $name == safari {
    @media not all and (min-resolution:.001dpcm) {  @content; }
  }
}