.testimonails {
    padding-top: 7rem;
    padding-bottom: 190px;
    background-image: url('../../images/Melon-Mobile-Green-Graphic-Background.svg');
    background-position: left top;
    overflow: hidden;
    margin-bottom: 6rem;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: transparent linear-gradient(180deg, #12963A 0%, #85C137 100%) 0% 0% no-repeat padding-box;
        left: 0;
        top: 0;
        z-index: -1;
    }
    //background-repeat: no-repeat;

    h2 {
        color: $ai-white;
        font-weight: 700;
        text-align: center;
        margin-bottom: 62px;
    }

    .title {
        @media(min-width: 1200px) {
            width: 50%;
            margin: 0 auto;
        }
    }

    .slide {
        padding: 0 8px;
        >div {
            background-color: $ai-white;
            padding: 45px;
            box-shadow: 0px 20px 30px #0000000D;
            border-radius: 25px;
            position: relative;
            padding-bottom: 120px;
            @media(max-width: 767px) { border-radius: 15px; }
        }

        .author {
            font-weight: 600;
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 0;
            p {
                font-size: clamp(1.3rem, 1.2333rem + 0.1215vw, 1.4rem);
            }

            img {
                width: 65px;
            }

            strong {
                font-weight: 600;
                font-family: $ai-fnt-mont;
                display: inline-block;
                margin-bottom: 15px;
                font-size: 22px;
                margin-left: 15px;
                @media(max-width: 991px) { font-size: 18px; }
            }
        }
    }

    .slick-dots {
        bottom: -90px;
        li {
            button {
                &:before {
                    font-size: 10px;
                    color: #D1DC23;
                }
            }
            &.slick-active {
                button {
                    &::before {
                        font-size: 14px;
                        color: #D1DC23;
                    }
                }
            }
        }
    }
}