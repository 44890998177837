//************************
//Accordion
//************************
.accordion-wrapper {
    padding-bottom: 6rem;
    h2 {
        margin-bottom: 4rem;
    }
}
.accordion {
    
    .question {
        background-color: rgba($ai-light-yellow, .3);
        padding: 30px 25px;
        border-radius: 25px;
        color: $ai-white;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all .3s;
        @media(max-width: 767px) { border-radius: 15px; }

        h4 {
            margin-bottom: 0;
            color: $ai-dark-green;
            font-size: 18px;
            font-weight: 700;
            @media(max-width: 991px) { font-size: 16px; }
        }

        .icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-size: 100%;
            transition: $transition;

            &.icon-arrow-closed {
                background-image: url('../../images/icons/arrow-right-s-line.svg');
            }
            &.icon-arrow-open {
                background-image: url('../../images/icons/arrow-up-s-line.svg');
            }
        }

        &.active, &:hover {
            background-color: $ai-dark-green;
            cursor: pointer;
            h4 {
                color: $ai-white;
            }
        }

        &:hover {
            .icon {
                &.icon-arrow-closed{
                    background-image: url('../../images/icons/arrow-right-s-line-light.svg');
                }
            }
        }
    }

    .answer {
        padding: 30px 25px;
        &.hide {
            display: none;
        }

        p {
            margin-bottom: 0;
            font-size: 17px;
            @media(max-width: 991px) { font-size: 14px; }
        }
    }
}