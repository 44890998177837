//************************
//Packages
//************************
.packages-list {
    padding-bottom: 7rem;
    .col-md-6 {
        display: flex;
    }

    .try-before-you-buy-icon{
        margin-block: 1rem;
    }
    .all-plan-link {
        padding-top: 4rem;
    }

    .deal {
        border: 2px solid $ai-green;
        border-radius: 25px;
        overflow: hidden;
        width: 100%;
        position: relative;
        padding-bottom: 100px;
        @media(max-width: 767px) { border-radius: 15px; }

        .header {
            background: transparent linear-gradient(1deg, #85C137 0%, #12963A 100%) 0% 0% no-repeat padding-box;
            text-align: center;
            color: $ai-white;
            padding: 20px 15px;
            padding-bottom: 15px;

            h2 {
                margin-bottom: 0;
                font-size: 24px;
            }
        }

        .icon {
            text-align: center;
            padding: 20px 0;

            img {
                width: 30%;
            }
        }

        .price {
            text-align: center;

            p {
                font-size: 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
            }

            strong {
                color: $range-input-red;
                font-size: 26px;
                font-family: $ai-fnt-mont;
                font-weight: 700;
            }
        }

        .details {
            width: fit-content;
            margin: 0 auto;
            
            p {
                font-size: 1.1rem;
                margin-bottom: .7rem;
            }

            img {
                width: 25px;
            }
        }

        .link {
            text-align: center;
            padding: 25px;
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            width: 90%;
            bottom: 10px;

            .btn {
                width: 100%;
            }
        }

        &.free {
            // background: transparent linear-gradient(180deg, #F6637E 0%, #D60926 100%) 0% 0% no-repeat padding-box;
            border-color: $ai-dark-red;
            .header {
                background: transparent linear-gradient(180deg, #F6637E 0%, #D60926 100%) 0% 0% no-repeat padding-box;
            }

            .details {
                margin-top: .5rem;
                p {
                    font-size: 16px;
                    margin-bottom: .7rem;
                }

                img {
                    width: 25px;
                }
            }

            .icon {
                
                img {
                    width: 115px;
                }
            }
        }
    }

    .col-md-6 {
        &:nth-child(3) {
            .icon {
                img {
                    margin-bottom: 1rem;
                }
            }
        }
        &:nth-child(5) {
            .icon {
                img {
                    margin-bottom: .7rem;
                }
            }
        }
    }

    &.all-packages {
        padding-bottom: 4.5rem;

        .tile {
            margin-bottom: 30px;
        }
    }
}

.package-tabs {
    padding-bottom: 7rem;
    .tab-buttons {
        display: flex;
        justify-content: center;
        padding-bottom: 60px;

        button {
            background-color: $ai-white;
            font-family: $ai-fnt-poppins;
            border: 2px solid $range-input-red;
            font-size: 16px;
            padding: 10px 20px;
            color: $ai-black;
            transition: $transition;
            @media(max-width: 767px) { font-size: 12px; }

            &.sliders {
                border-radius: 10px 0 0 10px;
            }
            &.tiles {
                border-radius: 0 10px 10px 0;
            }

            &.active, &:hover {
                background-color: $range-input-red;
                color: $ai-white;
                font-weight: 700;
            }
        }
    }

    .tab {
        display: none;
        &.showing {
            display: block;
        }
    }
}