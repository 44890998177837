//Image Reveal
.image-reveal {
    overflow: hidden;
    transition: .5s ease-out;
    transition-delay: 0.2s;
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
    visibility: hidden;

    img {
        transform: scale(1.3);
        transition: .7s ease-out;
    }

    &.slide-up {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        visibility: visible;
        transform: skewY(0);

        img {
            transform: scale(1);
            transition: 2s ease-out;
        }
    }
}

//Close Button
.close {
    width: 40px;
    height: 40px;
    background: transparent;
    border: 4px solid #E91E63;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin: 10px 20px;
}
.close:after {
    width: 24px;
    height: 4px;
    background-color: #E91E63;
    content: "";
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -2px;
    position: absolute;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    /*@include transform-origin(100%,100%);*/
}
.close:before {
    left: 50%;
    top: 50%;
    margin-left: -12px;
    margin-top: -2px;
    width: 24px;
    height: 4px;
    background-color: #E91E63;
    content: "";
    position: absolute;
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    /*@include transform-origin(0%,0%);*/
}
  
.close[data-animation="xMarks"] {
    border: 0px solid white;
    overflow: hidden;
    -moz-box-shadow: 0px 0px 0px 0px #E91E63 inset;
    -webkit-box-shadow: 0px 0px 0px 0px #E91E63 inset;
    box-shadow: 0px 0px 0px 0px #E91E63 inset;
    -moz-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    -o-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    -webkit-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
}
.close[data-animation="xMarks"]:before, .close[data-animation="xMarks"]:after {
    -moz-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    -o-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    -webkit-transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
    transition: cubic-bezier(0.175, 0.885, 0.52, 1.775) 200ms;
}
.close[data-animation="xMarks"]:not(.xMarks):hover {
    -moz-box-shadow: 0px 0px 0px 6px #E91E63 inset;
    -webkit-box-shadow: 0px 0px 0px 6px #E91E63 inset;
    box-shadow: 0px 0px 0px 6px #E91E63 inset;
}
.close[data-animation="xMarks"]:not(.xMarks):hover:before {
    -moz-transform: scale(0.7) rotate(45deg);
    -ms-transform: scale(0.7) rotate(45deg);
    -webkit-transform: scale(0.7) rotate(45deg);
    transform: scale(0.7) rotate(45deg);
    -moz-transition-delay: 100ms;
    -o-transition-delay: 100ms;
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
}
.close[data-animation="xMarks"]:not(.xMarks):hover:after {
    -moz-transform: scale(0.7) rotate(-45deg);
    -ms-transform: scale(0.7) rotate(-45deg);
    -webkit-transform: scale(0.7) rotate(-45deg);
    transform: scale(0.7) rotate(-45deg);
    -moz-transition-delay: 100ms;
    -o-transition-delay: 100ms;
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
}
  
.close.xMarks {
    -moz-transition: -moz-transform 400ms ease-out, opacity 200ms ease-in;
    -o-transition: -o-transform 400ms ease-out, opacity 200ms ease-in;
    -webkit-transition: -webkit-transform 400ms ease-out, opacity 200ms ease-in;
    transition: transform 400ms ease-out, opacity 200ms ease-in;
    -moz-transition-delay: opacity 100ms;
    -o-transition-delay: opacity 100ms;
    -webkit-transition-delay: opacity 100ms;
    transition-delay: opacity 100ms;
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
    -moz-box-shadow: 0px 0px 0px 20px #E91E63 inset;
    -webkit-box-shadow: 0px 0px 0px 20px #E91E63 inset;
    box-shadow: 0px 0px 0px 20px #E91E63 inset;
}
.close.xMarks:before {
    background-color: white;
    -moz-transform: scale(2) rotate(45deg);
    -ms-transform: scale(2) rotate(45deg);
    -webkit-transform: scale(2) rotate(45deg);
    transform: scale(2) rotate(45deg);
}
.close.xMarks:after {
    background-color: white;
    -moz-transform: scale(2) rotate(-45deg);
    -ms-transform: scale(2) rotate(-45deg);
    -webkit-transform: scale(2) rotate(-45deg);
    transform: scale(2) rotate(-45deg);
}