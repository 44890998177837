//**************************
//Secondary Banner
//**************************
.banner {
    min-height: 365px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    background-size: cover;
    margin-bottom: 7rem;
    padding-left: 30px;
    padding-right: 30px;
    background-attachment: fixed;
    background-position: center;
    @media(min-width: 1200px) {
        padding-left: 200px;
        padding-right: 200px;
    }
    @media(max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
        min-height: 300px;
    }

    h1, h2 {
        color: $ai-white;
        text-align: center;
        margin-bottom: 0;
    }

    h2 {
        font-weight: 400;
        font-family: $ai-fnt-poppins;
        strong {
            font-weight: 700;
        }
    }

    .headings {
        padding-bottom: 100px;
        @media(max-width: 767px) { padding-bottom: 60px; }
    }

    .badge {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        background-color: $banner-badge-red ;
        padding: 15px 60px;
        border-radius: 0 0 20px 20px;
        transition: $transition;

        &.slide-down {
            transform: translate(-50%, 0);
        }

        h1 {
            font-size: 34px;
            text-transform: uppercase;
            letter-spacing: 2.8px;
            @media(max-width: 991px) { font-size: 26px; }
            @media(max-width: 767px) { font-size: 18px; }
        }
    }
}

//************************************
//Contact Banner
//************************************
.contact-banner {
    height: 670px;
    background-size: cover;
    display: flex;
    align-items: center;
    color: $ai-white;
    margin-bottom: 5rem;
    background-attachment: fixed;
    position: relative;
    & > .container{
        .chat-bubble-icon{
            position: absolute;
            top: 15%;
            right: 0;

            @media screen and (min-width:768px) and (max-width: 991px){
                right: -7%;
            }

            @media screen and (max-width: 767px){
                bottom: 0;
                img{
                    position: absolute;
                    bottom: 1rem;
                    right: 1rem;
                    width: 30%;
                }
            }
        }
    }
    >div {
        width: 100%;
    }

    h1 {
        font-weight: 700;
        margin-bottom: 35px;
        font-size: 46px;
        @media(max-width: 991px) { font-size: 40px; }
    }

    h5 {
        font-weight: 700;
        margin-bottom: 30px;
        @media(max-width: 991px) { font-size: 18px; }
        @media(max-width: 767px) { margin-bottom: 14px; }
    }

    a {
        color: $ai-white;
        text-decoration: none;
        transition: $transition;
        border-radius: 50%;
        text-align: center;
        position: relative;

        &::after {
            pointer-events: none;
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            content: '';
            box-sizing: content-box;
            box-shadow: 0 0 0 3px $ai-white;
            top: 0;
            left: 0;
            opacity: 0;
            transition: 300ms;
        }

        &:hover {
            &::after {
                opacity: 1;
                transform: scale(1.15);
            }
        }
        svg {
            font-size: 30px;
        }
    }

    .socials {
        @media(max-width: 767px) { padding-bottom: 20px; }
        svg {
            font-size: 30px;
            margin-right: 15px;
        }
    }

    .stores {
        img {
            width: 22px;
            margin-right: 30px;
        }

        a {
            &::after {
                left: -3px;
            }
            &:nth-child(2) {
                &::after {
                    left: -6px;
                }
            }
        }
    }

    .whatsapp {
        @media(max-width: 767px) { padding-bottom: 20px; }
        img {
            width: 25px;
            margin-right: 15px;
        }

        a {
            font-weight: 600;
            &::after {
                left: -3px;
            }
        }
    }

    .col-md-6 {
        p {
            margin-bottom: 5.5rem;
        }
    }
}

.blogs-page {
    .banner {
        margin-top: 80px;
        height: 480px;
        justify-content: flex-start;
        padding: 35px;
        position: relative;
        background-size: cover;
        border-radius: 10px;
        overflow: hidden;
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: transparent linear-gradient(187deg, #85C13700 0%, #12963A 100%) 0% 0% no-repeat padding-box;
        }

        h1, p {
            width: 100%;
            color: $ai-white;
        }

        h1 {
            font-size: 40px;
            font-weight: 700;
        }

        p {
            font-size: 18px;
            font-weight: 300;
        }

        >div {
            position: relative;
            z-index: 1;
        }
    }

    .blog {
        border: 1px solid $ai-green;
        border-radius: 10px;
        overflow: hidden;
        display: block;
        text-decoration: none;
        color: $ai-black;
        img {
            width: 100%;
        }

        .content {
            padding: 20px;

            .excerpt {
                font-size: 1.2rem;
                font-weight: 700;
            }

            .date {
                font-size: 1rem;
            }
        }

        &:hover {
            color: $ai-white;

            .content {
                background-color: $ai-green;
            }
        }
    }
}

.package-banner {
    background-size: cover;
    background-position: center left;
    margin-bottom: 7rem;
    position: relative;
    background-attachment: fixed;

    .circle {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../../images/Melon-Mobile-Circle-Graphic.svg');
        background-attachment: fixed;
        z-index: 0;
        background-repeat: no-repeat;
        background-position-x: right;
        
    }

    .container {
        position: relative;
        z-index: 1;
    }

    .package-card {
        @media(max-width: 991px) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .row {
        justify-content: flex-end;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }

    .wrapper {
        background-color: $ai-white;
        border-radius: 25px;
        overflow: hidden;
        @media(max-width: 767px) { border-radius: 15px; }
    }

    .title {
        background: transparent linear-gradient(180deg, #85C137 0%, #12963A 100%) 0% 0% no-repeat padding-box;
        color: $ai-white;
        padding: 15px;
        padding-bottom: 11px;

        h1 {
            font-size: 30px;
            text-align: center;
            font-weight: 700;
            margin-bottom: 0px;
        }
    }

    .body {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        margin: 0 50px;
        // border-bottom: 1px solid $ai-light-grey;
        padding-bottom: 0;
        // @media(max-width: 767px) { margin: 0 20px; }
        @media screen and (min-width: 992px) and (max-width: 1199px) { margin: 0 10px; }
        @media screen and (min-width: 576px) and (max-width: 767px) { margin: 0 10px; }

        @media screen and (max-width: 575px) {
            .col-12{
                display: flex;
                justify-content: center;
            }
        }
        .body-right{
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 575px) {
                flex-direction: column;
                margin-top: 3rem;
                align-items: center;
                gap: 3rem;
                .cta{
                    width: 60%;
                    strong{
                        text-align: center;
                    }
                }
                img{
                    width: 35%;
                }
            }
        }
        .cta{
            display: flex;
            flex-direction: column;

            strong{
                font-size: 2rem;
            }

            & > div{
                margin-bottom: 1rem;
                font-size: 1.5rem;
                img{
                    margin-right: .5rem;
                }
            }
        }
        ul {
            margin-top: 15px;
            padding-left: 15px;
            font-size: 18px;

            li {
                @media(max-width: 1199px) { font-size: 16px; }
                @media(max-width: 767px) { font-size: 14px; }
            }
        }

        .icon {
            img {
                width: 165px;
                @media(max-width: 767px) { width: 100px; }
            }
        }
        .price{
            width: 150px;
            height: 150px;
            background-color: $ai-dark-red;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            p{
                font-size: 30px !important;
                text-align: center;
            }
            span{
                display: block;
            }

            &::after{
                position: absolute;
                content: '';
                width: 170px;
                height: 170px;
                border-radius: 50%;
                border: 5px solid $ai-green;

            }
        }
        .data, .price {
            p {
                color: $ai-white;
                font-weight: 700;
                font-size: 50px;
                font-family: $ai-fnt-mont;
                @media(max-width: 767px) { font-size: 30px; }

                span {
                    font-size: 1.5rem;
                }
            }
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        @media(max-width: 767px) { margin: 0 20px; }

        @media screen and (max-width: 575px){
            flex-direction: column-reverse;
            p{
                padding-left: 50px;
                text-align: center;
            }
        }
        .cta{
            flex: 1;
            display: flex;
            align-items: flex-start;
            justify-content: center;
        }

        p{
            flex: 1.5;
            padding-right: 50px;
        }
       
    }
}

.video-banner {
    img {
        width: 100%;
    }
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: fit-content;
        height: 80%;
        //background-color: white;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        overflow: visible;
    }

    .thumbnail-wrapper {
        position: relative;
        
        .play-icon {
            position: absolute;
            width: 100px;
            height: 100px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: $transition;
            outline: 0px solid rgba(#D1DC23, .2);
            border-radius: 50%;
        }

        &:hover {
            cursor: pointer;
            .play-icon {
                outline: 20px solid rgba(#D1DC23, .2);
                background-color: rgba(#D1DC23, .2);
            }
        }

    }
  
    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($ai-green, .9);
        opacity: 0.5;
        z-index: 11;
        
    }
  
    .modal-content {
        position: relative;
        z-index: 1;
        background-color: transparent;
        width: fit-content;
    }

    iframe {
        aspect-ratio: 16/9;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media(max-width: 767px) {
            max-height: 350px;
            max-width: 90vw;
        }
        @media(max-width: 500px) {
            max-height: 230px;
        }
    }
  
    .close {
        position: fixed;
        z-index: 11;
        top: 10px;
        right: 10px;
    }
}

.ai-large-image-banner {
    img {
        width: 100%;
        height: auto;
        margin-bottom: 2rem;
    }

    p {
        margin-bottom: 3rem;
    }

    .download-links {
        display: flex;
        justify-content: center;
        gap: 20px;

        a {
            transition: $transition;
            transform-origin: center;
            &:hover {
                opacity: .8;
            }
            &:last-child {
                img {
                    width: 168px;
                }
            }
        }
    }
}

.latest-post-banner {
    margin-bottom: 7rem;
    margin-top: 3.5rem;
    border-radius: 10px;
    height: 480px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    padding: 2.5rem;
    display: flex;
    align-items: flex-end;
    background-position: center;
    @media(max-width: 767px) { height: 300px; }

    a {
        text-decoration: none;
        h2 {
            transition: $transition;
        }
        &:hover {
            h2 {
                color: $range-input-red;
            }
        }
    }

    &::before {
        content: '';
        display: inline-block;
        background: transparent linear-gradient(187deg, #85C13700 0%, #12963A 100%) 0% 0% no-repeat padding-box;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    >div {
        position: relative;
        z-index: 1;
    }

    h2, p {
        color: $ai-white;
    }
}

.single-post-banner {
    position: relative;
    display: flex;
    margin-bottom: 7rem;
    background-attachment: fixed;
    
    img {
        width: 50%;
        margin-left: auto;
        height: 500px;
        object-fit: cover;
    }

    .container {
        position: absolute;
        left: 50%;
        bottom: 45px;
        transform: translateX(-50%);
    }

    h2, p {
        color: $ai-white;
    }
}

.error-banner {
    background: transparent linear-gradient(180deg, #C4E83E 0%, #2B902A 100%) 0% 0% no-repeat padding-box;
    position: relative;
    display: flex;
    margin-bottom: 7rem;
    @media(min-width: 992px) { height: calc(100vh - 142px); }
    @media(max-width: 767px) {
        display: block;
    }

    .image {
        margin-left: auto;
        width: 50%;
        @media(max-width: 767px) { 
            width: 100%; 
            margin-top: 40px;
        }
        img {
            width: 100%;
            margin-left: auto;
            height: calc(100vh - 142px);
            object-fit: cover;
        }
    }

    .container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media(max-width: 767px) {
            position: relative;
            left: 0;
            top: 0;
            transform: none;
            padding-top: 40px;
        }

        .col-md-6 {
            padding-right: 80px;
            @media(max-width: 991px) { padding-right: 15px; }
        }
    }

    h1, p {
        color: $ai-white;
    }

    h1 {
        font-weight: 700;
        font-family: $ai-fnt-poppins;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 30px;
        font-size: 17px;
        @media(max-width: 991px) { font-size: 14px; }
    }

    a {
        @media(max-width: 991px) { font-size: 14px; }
    }

    .links {
        display: flex;
        align-items: center;
        gap: 25px;

        a:last-child { color: $ai-white; }
    }
}