.melon-steps {
    padding-bottom: 7rem;
    .col-lg-3 {
        text-align: center;

        p {
            font-size: 16px;
            @media(max-width: 991px) { font-size: 14px; }
        }

        h3 {
            font-weight: 700;
            margin-bottom: 30px;
        }

        img {
            margin-bottom: 15px;
            width: 180px;
            height: 190px;
            object-fit: contain;
        }
    }

    .subtitle {
        p {
            margin-bottom: 3rem;
        }
    }

    .link {
        padding-top: 30px;
    }
}